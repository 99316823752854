<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>TOT Operation Platform</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
            >
              <v-flex>
                <v-alert
                  v-model="isLoginError"
                  type="error"
                  dismissible
                >
                  {{ loginErrorMessage }}
                </v-alert>
              </v-flex>
              <v-text-field
                id="username"
                v-model="username"
                label="Username"
                name="username"
                prepend-icon="mdi-account"
                type="text"
                :rules="usernameRules"
                required
                @keyup.enter="login"
              />

              <v-text-field
                id="password"
                v-model="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                :rules="passwordRules"
                required
                @keyup.enter="login"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              large
              block
              :loading="isLoginLoading"
              @click="login"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // source: String,
  },
  data() {
    return {
      drawer: null,
      username: '',
      password: '',
      submitted: false,
      usernameRules: [
        v => !!v || 'Username is required',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
      ],
    }
  },
  computed: {
    ...mapGetters({
      isLoginError: 'isLoginError',
      isLoginLoading: 'isLoginLoading',
      loginErrorMessage: 'loginErrorMessage',
    }),
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        const { username } = this
        const { password } = this
        this.$store.dispatch('login', { username, password })
          .then(() => this.$router.push('/dashboard'))
          // eslint-disable-next-line no-console
          .catch(error => console.log(error))
      }
    },
  },
}
</script>
